import React 			from 'react'
import { connect }		from 'react-redux'
import {
	Link
} from 'gatsby'

import PageLayout 	 	from '../components/page-layout'
import {
	SectionText
} from '../components/section'

class ErrorPage extends React.Component {
	render(){
		const {
			location
		} = this.props

		return <PageLayout
			title="404; page not found — Kiss House"
			location={ location }>
			<SectionText
				title="404"
				body={ `
					<p>The page you have requested could not be found.</p>
					<p>Please <a href="/">go to the homepage</a> and you will be able <br />to find what you are looking for from there.</p>
				` }
				bodyStyling={ true }
				/>
		</PageLayout>
	}
}

export default ErrorPage